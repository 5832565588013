.chat-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #FF7A00;
  color: white;
}

.exit-chat {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
}

.chat-input button {
  padding: 10px;
  background: #FF7A00;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}